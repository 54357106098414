import React, { useState } from 'react';
import { generateId } from '../helper/StringHelper';
import classNames from 'classnames';
import { Popover } from '@headlessui/react';

interface Props {
  type?: string,
  label: string,
  name: string,
  id?: string,
  value?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  onChange?: (event: any, stateName: any) => void,
  pattern?: string,
  step?: string,
  loading?: boolean,
  inlineStyling?: boolean,
  autoComplete?: boolean,
  autoCompleteTexts?: string[]
}

export const Input: React.FC<Props> = ({
  type = 'text',
  pattern = undefined,
  step = undefined,
  label,
  name,
  id = generateId(),
  value = '',
  placeholder,
  required = false,
  disabled = false,
  readonly = false,
  onChange = () => {},
  loading = false,
  inlineStyling = true,
  autoComplete = false,
  autoCompleteTexts = [],
}: Props) => {

  const onInternalChange = (event: any) => {
    onChange(event, name);
  };

  return (
	<div className={classNames('relative', {
	  'border border-gray-300 rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600': !inlineStyling,
	})}>
		{label !== '' &&
		<label htmlFor={id} className="block text-xs font-bold text-gray-900">
			{label}
		</label>
        }


		{autoComplete ?
			<Popover className="relative">
				<Popover.Button style={{ color: 'blue', textDecoration: 'underline', fontSize: 12 }}>
					Vorlage
				</Popover.Button>

				<Popover.Panel
                    className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
					{({ close }) => (
						<div
                            className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
							<div className="relative grid bg-white lg:grid-cols-1 text-base font-normal">
								{autoCompleteTexts.map((autoCompleteText, key) => <p style={{
	  borderBottomWidth: 1,
	  paddingLeft: 7,
	  paddingRight: 7,
	  padding: 8,
								}} key={key} onClick={() => {
	  onInternalChange({ target: { value: value + ' ' + autoCompleteText } });
	  close();
								}}>{autoCompleteText}</p>)}
							</div>
						</div>
					)}

				</Popover.Panel>
			</Popover>
		  : null}


		<input
                type={type}
                name={name}
                id={id}
                className={classNames( {
                  'pr-8': loading,
                  'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md': inlineStyling,
                  'mt-1': inlineStyling && label !== '',
                  'block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm': !inlineStyling,
                })}
                value={value}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                readOnly={readonly}
                onChange={onInternalChange}
                pattern={pattern}
                step={step}
            />

		{ loading &&
			<svg className={classNames('animate-spin -ml-1 mr-3 h-5 w-5 text-black absolute right-0', {
			  'top-1/2': inlineStyling,
			  'top-6': !inlineStyling,
			})} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
				<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
				<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
			</svg>
        }

	</div>
  );
};
