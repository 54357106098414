import React from 'react';
import { generateId } from '../helper/StringHelper';
import { Popover } from '@headlessui/react';

interface Props {
  label: string,
  name: string,
  value: string,
  id?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  onChange?: (event: any, stateName: any) => void,
  autoComplete?: boolean,
}

const texts = [

  'Problem vorführen lassen und aufgenommen.',
  'Fehlersuche begonnen.',
  'Fehleranalyse begonnen.',
  'Rücksprache mit Techniker gehalten.',
  'Praxis meldet Wechseln-Zu Fehler. Auf Rechner aufgeschaltet, Service Provider neugestartet, Funktionstest erfolgreich durchgeführt.',
  'Starface Backup erstellt. Starface Update durchgeführt. Module, Leitungen und Telefone geprüft.',
  'Server neugestartet, Snapshot erstellt, Sonderupdate eingespielt, Server neugestartet, Funktionstest durchgeführt, Snapshot gelöscht.',
  'Server neugestartet, Snapshot erstellt, Quartalsupdate eingespielt, Server neugestartet, Funktionstest durchgeführt, Snapshot gelöscht.',
];


export const Textarea: React.FC<Props> = ({
  label,
  name,
  id = generateId(),
  value,
  placeholder,
  required = false,
  disabled = false,
  onChange = () => {
  },
  autoComplete = false,
}: Props) => {

  const onInternalChange = (event: any) => {
    onChange(event, name);
  };

  return (
	<>

		<div
                className="relative border border-gray-300 rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
			<label htmlFor={id} className="block text-xs font-bold text-gray-900">
				{label}
				{autoComplete ?
					<Popover className="relative">
						<Popover.Button style={{ color: 'blue', textDecoration: 'underline' }}>
							Vorlage
						</Popover.Button>

						<Popover.Panel
                                className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
							{({ close }) => (
								<div
                                        className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
									<div className="relative grid bg-white lg:grid-cols-1 text-base font-normal">
										{texts.map((autoCompleteText, key) => <p style={{
	  borderBottomWidth: 1,
	  paddingLeft: 7,
	  paddingRight: 7,
	  padding: 8,
										}} key={key} onClick={() => {
	  onInternalChange({ target: { value: value + ' ' + autoCompleteText } });
	  close();
										}}>{autoCompleteText}</p>)}
									</div>
								</div>
							)}

						</Popover.Panel>
					</Popover>
	  : null}

			</label>

			<textarea
                    name={name}
                    id={id}
                    className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    onChange={onInternalChange}
                    autoComplete={'off'}
                    value={value}/>
		</div>
	</>

  );
};
